import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Text, TextContainer } from '../components/shared/Typography'
import Helmet from 'react-helmet'
import 'prismjs/themes/prism.css'
import './post.scss'
import styled from '@emotion/styled'

const Table = styled('table')`
  border-collapse: collapse;
  border: 1px solid #000;
  max-width: 100%;
  min-width: 600px;
  width: 100%;
`

const Post = props => {
  const post = props.data.markdownRemark
  const description = post.frontmatter.excerpt
  const canonical = post.fields.slug
  const {
    data: { site },
    location: { pathname }
  } = props
  const {
    siteMetadata: { siteUrl }
  } = site
  const title = post.frontmatter.title
  return (
    <>
      <Helmet>
        <title>{title}</title>

        <meta name='description' content={description} />
        <link rel='canonical' href={canonical} />

        <meta property='og:url' content={post.fields.slug} />
        <meta property='og:locale' content='en' />
        <meta property='og:title' content={title} />
        <meta property='og:site_name' content='UV-Soldier Online Store' />
        <meta property='og:description' content={description} />
      </Helmet>
      <TextContainer
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Heading>{title}</Heading>
        <Text
          className='post-content'
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </TextContainer>
    </>
  )
}

export default Post

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        excerpt
        title
        date(formatString: "YYYY-MM-DD", locale: "en-us")
        tags
      }
    }
  }
`
